<template>
  <div class="displayDetailView">
    <div v-if="typeLabel" class="help">
      <div class="help-label">{{ typeLabel }}</div>
      <!--      <div class="contribution" @click="toHotRank">-->
      <!--        <img src="@/assets/imgs/article/contribution.png" alt="" class="contribution-icon">-->
      <!--        <div class="des">问题贡献周榜 </div>-->
      <!--        <div class="value">3</div>-->
      <!--      </div>-->
    </div>
    <div class="displayDetailView_head">
      <topicUserInfo
        :userBasicVO="userBasicVO"
        class="headFlex"
        :user-name="isAnonymous ? '匿名' : userName"
        :time="createTime"
        :icon-url="isAnonymous ? '' : userAvatar"
        :admin="admin"
        @click.native="handleUserInfoClick"
      />
      <!-- todo 圈主和管理员有权限-->
      <div v-if="isShowAngleMenu()" class="dropdown-menu">
        <div class="flex-center-row img-box" @click="handleOpenMenuAction">
          <img class="arrow-down" src="/images/organization/icon-arrow.png" alt="">
        </div>
      </div>
      <!--      <follow v-if='status === 0 && isVote !== 1' :count='commonFollowCount' :myFollow='myFollow' :class="{'headFlex': attFormat.attCount > 0}"-->
      <!--              :show-line='attFormat.attCount > 0' @follow='handleFollow'/>-->
      <!--      <topicUserInfo :showBrowse="showBrowse" :browseCount="relationVO?relationVO.browseCount:0" v-if='attFormat.attCount > 0 && status !== 1' v-bind='attFormat' att-info class='headFlex' ellipsis/>-->
      <!--      <div class="displayDetailView_head_right">-->
      <!--        <p class="tag">-->
      <!--          <span class="label">浏览量</span>-->
      <!--          <span>{{ relationVO?relationVO.browseCount:0 }}</span>-->
      <!--        </p>-->
      <!--      </div>-->
    </div>
    <!-- <p class="title" v-if="isVote !== 1">{{ title }}</p> -->
    <topicContent
      isDetail
      showImgList
      :articleVoteVO="articleVoteVO"
      :topicList="topicList"
      :title="title"
      :content="content"
      :articleLabels="articleLabels"
      :attList="attList"
      :img-list="displayImageList"
      :videoAddr="videoAddr"
      :showVote="isVote === 1"
      :voteList="voteList"
      :pattern="pattern"
      :voteCount="voteCount"
      :isMeVoted="voteFlag"
      :voteStartTime="voteStartTime"
      :voteEndTime="voteEndTime"
      :isVoteExpand="true"
      :articleId="articleId"
      :circleShows="circleShow"
      @handleNewActive="handleNewActive"
      :locationTips="locationTips"
      @click="handleCheckVote"
      @vote="handlePublicVote"
      @tagClick="handleTagClick"
    />

    <topicOverview
      v-if="isForward"
      v-bind="originalDataFormat"
      @click.native="handleOriginalClick"
    />
    <!-- todo   删除帖子下拉箭头-->
    <van-action-sheet
      cancel-text="取消"
      v-model="isShowAction"
      :actions="menuOption"
      @select="handleSelect" />
  </div>
</template>

<script>
import topicUserInfo from "@/components/topicItem/components/userInfo";
import topicContent from "@/components/topicItem/components/content";
import topicOverview from "@/components/topicItem/components/overview";
// import follow from "@/components/topicItem/components/follow";
import * as quoteType from "@/constant/quoteType";
import {ADMIN_NAME} from "@/config/config";
import {articleRemove} from "@/api/article";

export default {
  props: {
    userBasicVO: {
      type: Object,
      default: () => {
      }
    },
    title: String,
    userAvatar: String,
    anonymous: Boolean,
    userName: String,
    articleId: String,
    quoteType: Number,
    browseCount: Number,
    content: String,
    articleLabels: Array,
    attList: Array,
    imageList: Array,
    videoAddr: String,
    createTime: String,
    quoteArticleVO: Object,
    superAdmin: Number,
    isVote: Number,
    voteList: Array,
    pattern: Number,
    voteCount: Number,
    voteFlag: Boolean,
    relationVO: Object,
    voteStartTime: String,
    voteEndTime: String,
    commonFollowCount: Number,
    myFollow: Boolean,
    status: Number,
    typeLabel: String,
    articleVoteVO: Object,
    topicList: Array,
    circleShow: Boolean,
    locationTips: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    topicUserInfo,
    topicContent,
    topicOverview,
    // follow
  },
  data() {
    return {
      // 删除帖子下拉箭头
      isShowAction: false,
      menuOption: [
        {
          name: '删除帖子',
          type: 'delete'
        }
      ],
    }
  },
  computed: {
    isAnonymous() {
      return this.anonymous;
    },
    displayImageList() {
      return this.imageList;
    },
    isForward() {
      return quoteType.FORWARD === this.quoteType;
    },
    originalDataFormat() {
      let {
        quoteArticleVO: {
          content,
          title,
          id,
          imageList,
          userName,
          superAdmin,
          anonymous,
          isVote,
          voteCount,
          voteFlag,
          voteStartTime,
          voteEndTime,
          voteList,
        } = {},
      } = this;
      const [imgUrl] = (imageList || "").split(",");
      if (anonymous) {
        userName = "匿名";
      } else if (superAdmin === 1 || superAdmin === 2) {
        userName = ADMIN_NAME;
      }
      return {
        content,
        title,
        id,
        imgUrl,
        userName,
        superAdmin,
        isVote,
        voteCount,
        voteFlag,
        voteStartTime,
        voteEndTime,
        voteList,
      };
    },
    admin() {
      if (this.isVote === 1) {
        return 2;
      }
      return this.superAdmin;
    },
    attFormat() {
      const {attList} = this;
      return attList && attList.length
        ? {
          iconUrl: attList[0].attAvatar,
          attCount: attList.length,
          userName: attList[0].attName,
        }
        : {};
    },
    showBrowse() {
      return !(this.attFormat.attCount > 0 && this.status !== 1);
    },
  },
  methods: {
    // 是否显示下拉箭头
    isShowAngleMenu() {
      if (this.$route.path !== "/app/home/topicDetail") {
        return false
      }
      if (!this.userBasicVO.userId) {
        return false
      }
      const userDetail = this.$store.state.user.userDetail || {}
      if (this.userBasicVO.userId !== userDetail.userId) {
        return false
      }
      if (this.title === '本贴已被删除' || this.content === '本贴已被删除') {
        return false
      }
      return true
    },
    // 打开菜单弹框
    handleOpenMenuAction() {
      this.isShowAction = true
    },
    // 选择菜单回调
    handleSelect() {
      this.$dialog.confirm({
        title: '删除帖子',
        message: '你确定要删除该帖子吗？',
        confirmButtonText: '删除帖子',
        cancelButtonText: '再考虑下'
      }).then(() => {
        articleRemove({articleId: this.articleId}).then(() => {
          this.$emit('deleteArticle', true)
        });
      })
      this.isShowAction = false
    },
    handleToolbarClick({key} = {}) {
      this.$emit(key);
    },
    handleOriginalClick() {
      this.$emit("originalTopicClick", this.originalDataFormat);
    },
    handleCheckVote(item) {
      this.$emit("checkVote", item);
    },
    handlePublicVote() {
      this.$emit("vote");
    },
    handleTagClick(tag) {
      this.$emit("tagClick", tag);
    },
    handleUserInfoClick() {
      if (this.isAnonymous || this.isVote) return;
      this.$emit("userInfoClick");
    },
    handleFollow() {
      this.$emit("follow");
    },
    toHotRank() {
      this.$router.push({
        path: "/app/home/popular",
      });
    },
    handleNewActive(item) {
      this.$emit("handleNewActive", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.displayDetailView {
  padding: 20px;
  background-color: white;

  .title {
    color: $titleColor;
    font-size: 22px;
    font-weight: bold;
    word-wrap: break-word;
    margin: 10px 0 10px 0;
  }

  .help {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 8px;

    .help-label {
      background: rgba(254, 88, 0, 0.1);
      color: $mainColor;
      padding: 4px 8px;
      border-radius: 2px;
      font-size: 13px;
    }

    /*.contribution{
      display: flex;
      align-items: center;
      .contribution-icon{
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      .des{
        color: #6D7278;
        font-size: 12px;
        margin-right: 4px;
      }
      .value{
        color: $mainColor;
        font-size: 12px;
      }
    }*/
  }

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img-box{
      .arrow-down{
        width: 20px;
        height: 20px;
        object-fit: cover;
      }
    }

    .headFlex {
      margin-bottom: 14px;
      flex: 1;
    }

    &_right {
      display: flex;

      .tag {
        &:not(:last-child) {
          margin-right: 5px;
        }

        color: #f23030;
        background-color: #ffeeeb;
        font-size: 12px;
        font-weight: bold;
        height: 22px;
        padding: 0 10px;
        line-height: 22px;
        border-radius: 11px;
        flex-shrink: 0;
        text-align: center;

        .label {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
