<template>
  <div class="sortKeySelectView">
    <p class="sortKeySelectView_content" @click="showPicker = true">
      <span>{{ displayVal }}</span>
    </p>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="options"
          value-key="label"
          @cancel="showPicker = false"
          @confirm="handleConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showPicker: false,
    }
  },
  computed: {
    displayVal() {
      const {label} = this.optionsMap.get(this.value) || {}
      return label
    },
    optionsMap() {
      const tempMap = new Map()
      this.options.forEach(item => {
        const {value} = item
        tempMap.set(value, item)
      })
      return tempMap
    }
  },
  methods: {
    handleConfirm({value} = {}) {
      this.$emit('input', value)
      this.showPicker = false
    }
  }
}
</script>

<style lang="scss" scoped>
.sortKeySelectView {
  &_content {
    color: $remarkColor;
    font-size: 14px;
  }
}
</style>
