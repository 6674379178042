<template>
  <van-popup
    v-model="isOpen"
    close-icon-position="top-left"
    position="bottom"
    :style="{ height: '70%' }"
  >
    <div class="replyDetailView">
      <!--      <div class="head">-->
      <!--        <p>回复详情</p>-->
      <!--      </div>-->
      <div class="content" @scroll="handleScroll($event)" ref="replyContent">
        <commentItem
          v-for="(item, index) in commentList"
          :commenitem="item"
          :key="item.commentId"
          v-bind="item"
          :showReplyBtn="true"
          :isAuthor="item.isTopEntityUser"
          :username="item.userBasicVO.username"
          :show-like="true"
          @reply="handleReply(item)"
          @touch="handleTouch(item, index)"
          @touchend="handleTouchend"
          @delete="handleDelete(item, index)"
          @userInfoClick="handleUserinfoClick(item.userId)"
           @like="handleCommentLike({ ...item, isLike: $event })"
        />
        <no-more v-if="showNoMore" />
      </div>
    </div>
  </van-popup>
</template>

<script>
import NoMore from '@/components/uitls/NoMore'
import commentItem from './commentItem'
import { addThumbsup, removeThumbsup } from "@/api/thumbsup";
export default {
  components: {
    commentItem,
    NoMore
  },
  props: {
    commentList: Array,
    showNoMore: Boolean
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    handleReply(item) {
      this.$emit('reply', item)
    },
    handleScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target
      // console.log(scrollTop, scrollHeight, clientHeight);
      if (scrollHeight - scrollTop === clientHeight) {
        this.$emit('loadMore')
      }
    },
    handleTouch(item, index) {
      this.$emit('touch', { item, index })
    },
    handleTouchend() {
      this.$emit('touchend')
    },
    handleDelete(item, index) {
      this.$emit('delete', { item, index })
    },
    handleUserinfoClick(userId) {
      this.$emit('userInfoClick', userId)
    },
     /**
     * 评论点赞/取消点赞
     */
    async handleCommentLike({
      isLike: isThumbsUpFlag,
      commentId: entityId,
    } = {}) {
      await this.submitLike({ isThumbsUpFlag, entityId, entityType: 2 });
      this.$emit( 'likeReply',{entityId,isThumbsUpFlag} )
    },
     /**
     * 提交点赞/取消点赞
     */
    async submitLike({ isThumbsUpFlag, entityId, entityType }) {
      if (isThumbsUpFlag) {
        await removeThumbsup({ entityId, entityType });
       
      } else {
        await addThumbsup({ entityId, entityType });
      
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.replyDetailView {
  height: 100%;

  .content {
    // display: flex;
    // flex-direction: column;
    height: calc(100% - 40px);
    overflow-y: auto;
    padding: 20px;
  }
}
</style>
