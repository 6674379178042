<template>
  <div class="interactionTypeSelect">
    <p v-for="item in options" :key="item.key" class="interactionTypeSelect_item" @click="handleActive(item.key)"
       :class="activeName===item.key?'interactionTypeSelect_itemActive':'interactionTypeSelect_itemNormal'">
      <span class="label">{{ item.label }}</span>
      <span class="value">{{ data[item.valueKey] || 0 }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    activeName: String,
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleActive(key) {
      this.$emit('update:activeName', key)
      this.$emit('change', key)
    }
  }
}
</script>

<style lang="scss" scoped>
.interactionTypeSelect {
  display: flex;
  font-size: 14px;

  &_item {
    margin-right: 10px;

    .label {
      margin-right: 5px;
    }

    .value {

    }
  }

  &_itemNormal {
    color: $remarkColor;
  }

  &_itemActive {
    color: $mainColor;
  }
}
</style>
