<template>
  <div class="toolbarView">
    <input :value="value" @input="handleInput" placeholder="说说你的想法" @click="handleInputFocus" readonly/>
    <div class="toolbarView_tool" :class="isLike?'toolbarView_toolActive':'toolbarView_toolNormal'" @click="handleLike">
<!--      <div>-->
        <img :src="`/images/topicDetail/like${isLike?'Active':'Normal'}.png`">
<!--      </div>-->
      <p>点赞</p>
    </div>
<!--    <div class="toolbarView_tool toolbarView_toolNormal" @click="handleShare">
&lt;!&ndash;      <div>&ndash;&gt;
        <img src="/images/topicDetail/share.png">
&lt;!&ndash;      </div>&ndash;&gt;
      <p>转发</p>
    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    isLike: Boolean
  },
  methods: {
    handleInputFocus() {
      this.$emit('inputFocus')
    },
    handleInput(e) {
      const {value} = e.target || {}
      this.$emit('input', value)
    },
    handleLike() {
      this.$emit('like', this.isLike)
    },
    handleShare() {
      this.$emit('share')
    }

  }
}
</script>

<style lang="scss" scoped>
.toolbarView {
  display: flex;
  background-color: white;
  padding: 7px 20px;
  align-items: center;

  input {
    width: 0;
    flex: 1;
    border: none;
    outline: none;
    background-color: $whiteGray;
    height: 36px;
    border-radius: 18px;
    font-size: 14px;
    padding: 0 18px;
  }

  &_toolActive {
    p {
      color: $mainColor;

    }
  }

  &_toolNormal {
    p {
      color: $remarkColor;
    }
  }

  &_tool {
    display: flex;
    align-items: center;
    margin-left: 10px;

    img {
      width: 14px;
      margin-right: 5px;
    }

    p {
      font-size: 12px;
      line-height: 12px;
    }
  }
}
</style>
