<template>
  <div
    class="commitItem"
    @touchstart="handleTouch()"
    @touchend="handleTouchend"
  >
    <div class="commitItem_head">
      <userInfoCard
        :userBasicVO="commenitem.userBasicVO"
        :index="index"
        :sort-key="sortKey"
        :isAuthor="isAuthor"
        :postTotal="postTotal"
        :isFloor="isFloor"
        :hideRemove="hideRemove"
        :user-name="isAnonymous ? '匿名' : username"
        :time="createTime"
        :icon-url="isAnonymous ? '' : userAvatar"
        :admin="superAdmin || userSuperAdmin"
        :showReply="toId && toId !== -1"
        :toName="isToAnonymous ? '匿名' : toName"
        :toSuperAdmin="toSuperAdmin"
        @reply="handleReplyStair"
      >
        <template #content>
          <div class="commitItem_content_text">
            <div class="contentText">
              <span
                @click.stop="handleToUserDetail(item.toUserId)"
                v-for="(item, index) in attList"
                :key="index"
                class="att"
              >@{{ item.toUsername }}
              </span>
              <div v-if="commenitem.type === 3">
                <span>回复 </span>
                <span class="name">{{ commenitem.replyToUsername }}: </span>
                <span>{{ content }}</span>
              </div>
              <div @click="handleReplyTwoStair(commenitem)" v-else>{{ content }}</div>
            </div>
          </div>
        </template>
        <template #reply>
          <span class="replyBtn" v-if="showReplyBtn" @click.stop="handleReply">
            回复
          </span>
        </template>
      </userInfoCard>
      <div class="operate">
        <van-icon
          name="delete-o"
          size="16"
          color="#999999"
          v-if="showDelete"
          @click.stop="handleDelete"
        />
        <div class="like" v-if="showLike" @click.stop="handlerLike">
          <img
            :src="`/images/topicDetail/like${isLike ? 'Active' : 'Normal'}.png`"
            class="icon"
          />
          <p class="num">{{ likeNum || 0 }}</p>
        </div>
      </div>
    </div>
    <div class="commitItem_content">
      <img class="contentImg" :src="mainImgUrl" v-if="mainImgUrl" />
      <template v-if="commentNum > 0">
        <div class="comment" @click.stop="handleLoadMore">
          <div
            class="comment_item"
            v-for="commentItem in commentList.slice(0, 2)"
            :key="commentItem.commentId"
          >
            <userInfoCard
              :userBasicVO="commentItem.userBasicVO"
              :isAuthor="commentItem.isTopEntityUser"
              :user-name="
                commentItem.isAnonymous
                  ? '匿名'
                  : commentItem.userBasicVO.username
              "
              :icon-url="
                commentItem.isAnonymous ? '' : commentItem.userBasicVO.avatar
              "
            >
              <template #content>
                <div class="content">
                  <div>
                    <div @click.stop="handleReplyTwoStair(commentItem)" class="reply-container" v-if="commentItem.type === 3">
                      <span class="text">回复 </span>
                      <span class="name">{{ commentItem.replyToUsername }}:</span>
                      <span class="text">{{ commentItem.content }}</span>
                    </div>

                    <span v-else class="text" @click.stop="handleReplyTwoStair(commentItem)">{{
                        commentItem.content
                      }}</span
                    ><span
                    @click.stop="handleReplyTwoStair(commentItem)"
                    class="time"
                    :style="{
                        display: commentItem.content.length >= 10 ? 'flex' : ''
                      }"
                  >{{ timeFormat(commentItem.commentTime) }}</span
                  >
                  </div>
                  <div class="replyOperate">
                    <van-icon
                      name="delete-o"
                      size="16"
                      color="#999999"
                      v-if="showReplyDelete(commentItem.userBasicVO.userId)"
                      @click.stop="handleReplyDelete(commentItem)"
                    />
                    <div
                      class="like"
                      v-if="showLike"
                      @click.stop="handlerReplyLike(commentItem)"
                    >
                      <img
                        :src="`/images/topicDetail/like${
                          commentItem.isThumbsUp ? 'Active' : 'Normal'
                        }.png`"
                        class="icon"
                      />
                      <p class="num">{{ commentItem.thumbsUpCount || 0 }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </userInfoCard>

            <!--
              <span class="label">{{ getUserName(commentItem) }}:</span>

              <span v-if="commentItem.type === 3">
                <span class="value">回复 </span>
                <span>{{ commentItem.replyToUsername }}: </span>
              </span>
              <span class="value">{{ commentItem.content }}</span> -->
          </div>

          <p
            class="loadMore"
            v-if="commentNum >= 2"
            @click.stop="handleLoadMore"
          >
            <span>查看全部</span>
            <span>{{ total }}</span>
            <span>条回复 ></span>
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import userInfoCard from '@/components/topicItem/components/userInfo'
import {ADMIN_NAME} from '@/config/config'
import {timeFormat} from '@/utils/dayjs'

export default {
  props: {
    sortKey: String,
    anonymous: Boolean,
    toAnonymous: Number,
    superAdmin: Number,
    userSuperAdmin: Number,
    toSuperAdmin: Number,
    userAvatar: String,
    username: String,
    showLike: Boolean,
    isLike: Boolean,
    likeNum: Number,
    contentText: String,
    content: String,
    attList: Array,
    mainImgUrl: String,
    createTime: String,
    index: Number,
    postTotal: Number,
    isFloor: Boolean,
    commentList: {
      type: Array,
      default: () => []
    },
    commenitem: {
      type: Object,
      default: () => []
    },
    total: Number,
    toId: {
      type: Number,
      default: () => -1
    },
    toName: String,
    isAuthor: {
      type: Boolean,
      default: () => false
    },
    showReplyBtn: {
      type: Boolean,
      default: () => false
    },
    id: [Number, String],
    userId: Number,
    hideRemove: Boolean
  },
  computed: {
    isAnonymous () {
      const {anonymous} = this
      return anonymous
    },
    isToAnonymous () {
      const {toAnonymous} = this
      return parseInt(toAnonymous) === 1
    },
    commentNum () {
      return this.commentList.length
    },
    showDelete () {
      if (this.hideRemove) return false
      const {user_id} = this.$store.getters.user
      if (parseInt(user_id) === parseInt(this.userId)) {
        return true
      }
      return false
    }
  },
  components: {
    userInfoCard
  },
  methods: {
    timeFormat,
    showReplyDelete (id) {
      const {user_id} = this.$store.getters.user
      if (parseInt(user_id) === parseInt(id)) {
        return true
      } else {
        return false
      }
    },
    handlerLike () {
      this.$emit('like', this.isLike)
    },
    handlerReplyLike (item) {
      this.$emit('like', {isReply: true, ...item})
    },
    handleReplyTwoStair (item) {
      console.log('item-------------------------')
      console.log(item)
      this.$emit('replyTwoStair', item)
    },
    handleReplyStair () {
      this.$emit('click')
    },
    handleReply () {
      this.$emit('reply')
    },
    handleLoadMore () {
      this.$emit('loadMore', this.id)
    },
    getUserName (item) {
      // return item.isAnonymous?'匿名':(item.superAdmin===0?(item.replyToUsername || item.replyToUsername):ADMIN_NAME)
      return item.isAnonymous ? '匿名' : item.userBasicVO.username
    },
    getToName (item) {
      return item.toAnonymous === 1
        ? '匿名'
        : item.toSuperAdmin === 0
          ? item.toName
          : ADMIN_NAME
    },
    handleTouch () {
      this.$emit('touch')
    },
    handleTouchend () {
      this.$emit('touchend')
    },
    handleDelete () {
      this.$emit('delete')
    },
    handleReplyDelete (item) {
      this.$emit('deleteReply', item)
    },
    handleUserinfoClick () {
      if (this.isAnonymous) return
      this.$emit('userInfoClick')
    },
    /**
     * 跳转用户详情页
     */
    handleToUserDetail (userId) {
      this.$router.push({name: 'userInfoDetail', params: {userId}})
    }
    // timeFormat(time) {
    //   return time.substring(time.indexOf('-') + 1, time.lastIndexOf(':'))
    // }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .userInfoView {
    width: 100%;

    .userInfoView_text {
      width: 100% !important;
    }
  }
}

.replyOperate {
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: auto;

  .like {
    display: flex;
    align-items: center;
    margin-left: 5px;

    .icon {
      width: 14px;
      margin-right: 5px;
    }

    color: #999999;
    font-size: 12px;
  }
}

.content {
  display: flex;
}

.replyBtn {
  font-size: 10px;
}

::v-deep {
  .commitItem_content {
    .userInfoView_username {
      margin-bottom: 8px !important;
    }

    .userInfoView_icon {
      width: 24px;
      height: 24px;
    }
  }
}

.name {
  color: #666666 !important;
}

.commitItem_content_text {
  .contentText {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.8) !important;
    margin-bottom: 8px !important;
  }
}

.commitItem {
  margin-bottom: 20px;

  .commitItem_content {
    border-bottom: 1px solid #cccccc;

    .reply-container {
      display: inline-block;
    }

    .name {
      color: #666666 !important;
    }

    .text {
      display: inline-block;
      margin-right: 4px;

      color: rgba(0, 0, 0, 0.8);
    }

    .time {
      font-size: 10px;
      color: #999999;
    }
  }
}

.commitItem {
  &_head {
    display: flex;
    justify-content: space-between;
    position: relative;

    .operate {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .like {
        display: flex;
        align-items: center;
        margin-left: 5px;

        .icon {
          width: 14px;
          margin-right: 5px;
        }

        .num {
          @include remark;
        }
      }
    }
  }

  &_content {
    margin-left: 48px;
    padding-bottom: 20px;

    &_text {
      display: flex;

      .contentText {
        @include mainText;
        margin: 10px 0 0 0;
        word-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
        overflow: hidden;
        flex: 1;

        .att {
          color: $mainColor;
        }
      }
    }

    .contentImg {
      width: 100px;
      border-radius: 4px;
    }

    .imgList {
      display: flex;
      flex-wrap: wrap;
    }

    .comment {
      padding: 10px;
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-top: 2px;

      &_item:not(:last-child) {
        margin-bottom: 5px;
      }

      &_item {
        @include mainText;
        @include ellipsis(2);
        margin: 0;

        .label {
          font-weight: bold;
        }

        .value {
          color: $remarkColor;
          word-wrap: break-word;
          word-break: break-all;
        }
      }

      .loadMore {
        margin-top: 12px !important;
        color: #fe5800 !important;
        font-size: 12px;
        margin: 0;
      }
    }
  }
}
</style>
